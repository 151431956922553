
export default {
  props: {
    card: {
      type: Object,
      default: () => {},
    },

    linkText: {
      type: String,
      default: null,
    },

    showBackgroundColor: {
      type: Boolean,
      default: false,
    },
  },
}
