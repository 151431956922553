
export default {
  props: {
    /**
     * Only animate when ready
     * when FALSE the css animation classes
     * are still applied
     */
    ready: {
      type: Boolean,
      default: true,
    },

    /**
     * How many seconds
     * GSAP should wait initially
     * until it plays the animatin
     */
    initialDelay: {
      type: Number,
      default: null,
    },

    /**
     * Disable all animations
     * and make everything visible when necessary
     *
     * (like there were no animations set)
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Workaround
     * without the scroll trigger for the newsSlider items ist of the page
     *
     *
     */
    delay: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      tl: null,
      inLivePreview: false,
    }
  },

  watch: {
    ready: {
      async handler(isReady) {
        await this.$nextTick()

        await new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, this.delay)
        })

        // do not animate when...
        if (!isReady || this.inLivePreview || !this.$el) {
          return
        }

        // otherwise animate that shit:
        const q = this.$gsap.utils.selector(this.$el)

        // all further animations will be added to this gsap Timeline
        this.tl = this.$gsap.timeline({
          delay: this.initialDelay,
          scrollTrigger: {
            trigger: this.$el,
            start: 'top 85%',
            markers: false,
          },
        })

        if (q('.fade-in').length > 0) {
          this.tl.add(
            this.$gsap.to(q('.fade-in'), {
              opacity: 1,
              duration: 1,
              stagger: 0.2,
            })
          )
        }

        if (q('.fade-in-fast').length > 0) {
          this.tl.add(
            this.$gsap.to(q('.fade-in-fast'), {
              opacity: 1,
              duration: 0.6,
              stagger: 0.1,
            })
          )
        }

        if (q('.slide-in-left').length > 0) {
          this.tl.add(
            this.$gsap.to(q('.slide-in-left'), {
              x: 0,
              opacity: 1,
              duration: 0.3,
              stagger: 0.1,
            })
          )
        }

        if (q('.slide-up').length > 0) {
          this.tl.add(
            this.$gsap.to(q('.slide-up'), {
              y: 0,
              opacity: 1,
              duration: 0.8,
              stagger: 0.15,
            })
          )
        }

        if (q('.slide-up-fast').length > 0) {
          this.tl.add(
            this.$gsap.to(q('.slide-up-fast'), {
              y: 0,
              opacity: 1,
              duration: 0.8,
              stagger: 0.2,
            })
          )
        }
      },

      immediate: true,
    },
  },

  mounted() {
    this.checkIfIsInLivePreview()
  },

  methods: {
    checkIfIsInLivePreview() {
      this.inLivePreview = !!this.$route.query['live-preview']
    },
  },
}
