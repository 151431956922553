
import { mapState } from 'vuex'
import {
  getBlogPosts,
  getBlogPostsByTag,
} from '~/graphql/fragments-collection.js'

export default {
  inject: ['pageId'],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isTouchDevice: true,
      newsArticles: [],
    }
  },

  async fetch() {
    if (!this.data.load_automatically || this.data.amount_news_articles === 0) {
      return
    }

    await this.getBlogPosts()
  },

  computed: {
    ...mapState('globals', ['newsDefaults']),

    correspondingNewsArticles() {
      if (
        this.data.load_automatically ||
        !Array.isArray(this.data.blog_posts) ||
        this.data.blog_posts.length === 0
      ) {
        return this.newsArticles
      }

      return this.data.blog_posts
    },

    hasCategory() {
      return this.data.category?.slug
    },
  },

  watch: {
    data: {
      async handler(newData) {
        if (!newData.load_automatically || newData.amount_news_articles === 0) {
          return
        }

        await this.getBlogPosts()
      },

      deep: true,
    },
  },

  mounted() {
    this.isTouchDevice = 'ontouchstart' in window
  },

  methods: {
    async getBlogPosts() {
      try {
        const res = await this.$graphql.cms.request(
          this.hasCategory ? getBlogPostsByTag : getBlogPosts,
          {
            lang: this.$i18n.locale,
            limit: parseInt(this.data.amount_news_articles || 5),
            currentId: this.pageId,
            category: this.data.category?.slug,
          }
        )
        this.newsArticles = res.entries?.data
      } catch (e) {
        console.log('NewsSlider/getBlogPosts', e)
      }
    },
  },
}
