
import throttle from 'lodash/throttle'
import requestInterval from '~/utils/requestInterval'
import outerWidth from '~/utils/outerWidth'

export default {
  props: {
    mousePadding: {
      type: Number,
      default: 120,
    },

    softness: {
      type: Number,
      default: 16,
    },
  },

  data() {
    return {
      elementLeft: 0,
      elementWidth: 1,
      scrollWidth: 1,
      scrollPositionX: 0,
      mousePositionX: 0,
      mousePositionXModified: 0,
      isTouching: true,
      isScrolling: undefined,
    }
  },

  computed: {
    widthRatio() {
      return this.scrollWidth / this.elementWidth - 1
    },

    mousemoveArea() {
      return this.elementWidth - this.mousePadding * 2
    },

    mousemoveAreaRatio() {
      return this.elementWidth / this.mousemoveArea
    },
  },

  mounted() {
    const delay = (Math.random() * (0.1 - 1) + 1).toFixed(2)

    // Workaround with delay.
    // When there are multiple instances of this component on the same page.
    this.$gsap.delayedCall(delay, () => {
      this.$el.addEventListener('mousemove', this.mousemoveHandler)
      this.$el.addEventListener('touchmove', this.touchmoveHandler, {
        passive: true,
      })
      this.$el.addEventListener('touchend', this.touchendHandler)
      window.addEventListener('resize', this.resizeHandler)

      this.resizeHandler()

      requestInterval(() => {
        if (!this.isTouching) {
          this.scrollPositionX +=
            (this.mousePositionXModified - this.scrollPositionX) / this.softness // zeno's paradox equation "catching delay"
          this.$el.scrollLeft = this.scrollPositionX * this.widthRatio
        }
      }, 1000 / 60)
    })
  },

  beforeDestroy() {
    this.$el.removeEventListener('mousemove', this.mousemoveHandler)
    this.$el.removeEventListener('touchmove', this.touchmoveHandler)
    this.$el.removeEventListener('touchstart', this.touchstartHandler())
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    mousemoveHandler: throttle(function (e) {
      this.mousePositionX = e.pageX - this.elementLeft - this.$el.offsetLeft
      this.mousePositionXModified =
        Math.min(
          Math.max(0, this.mousePositionX - this.mousePadding),
          this.mousemoveArea
        ) * this.mousemoveAreaRatio
      this.isTouching = false
    }),

    touchmoveHandler: throttle(function (e) {
      this.isTouching = true
    }),

    touchstartHandler() {
      this.isTouching = true
    },

    resizeHandler: throttle(function (e) {
      this.elementLeft = this.$el.getBoundingClientRect().left
      this.elementWidth = outerWidth(this.$el)
      this.scrollWidth = this.$el.scrollWidth
    }, 50),
  },
}
